body {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* padding: 0.5rem 0.2rem; */
  color: #333;
}

.month_wrapper {
  border-radius: 0.3rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  padding: 0.2rem;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.2rem;
  padding: 0 0.2rem 0.2rem;
  user-select: none;
}

.header .current {
  font-size: 1.1em;
  font-weight: bold;
  cursor: pointer;
}

.header .btn {
  cursor: pointer;
}

.header .btn.arrow {
  font-size: 1.2em;
  line-height: 1;
}

.dates_wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 18.2rem;
}

.dates_wrapper .week,
.dates_wrapper .date {
  font-size: 0.9rem;
  text-align: center;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dates_wrapper .week:nth-child(7n),
.dates_wrapper .date:nth-child(7n),
.dates_wrapper .week:nth-child(7n + 1),
.dates_wrapper .date:nth-child(7n + 1) {
  color: #44a2a7;
}

.dates_wrapper .week {
  font-size: 1.2em;
}

.dates_wrapper .date {
  animation: fadein 1s both;
}

.dates_wrapper .date.today {
  color: #fac176;
  font-weight: bold;
  position: relative;
}

.dates_wrapper .date.today:before {
  content: "";
  bottom: 15%;
}

.dates_wrapper .date.disabled {
  color: rgba(68, 162, 167, 0.3);
}

.emotion_icon {
  width: 20px;
  height: 20px;
}

.happy {
  background: url("../../../public/images/emotions/emotionicons_happy.svg")
    no-repeat;
}

.energized {
  background: url("../../../public/images/emotions/emotionicons_energized.svg")
    no-repeat;
}

.medium {
  background: url("../../../public/images/emotions/emotionicons_tired.svg")
    no-repeat;
}

.stressed {
  background: url("../../../public/images/emotions/emotionicons_stressed.svg")
    no-repeat;
}

.sad {
  background: url("../../../public/images/emotions/emotionicons_sad.svg")
    no-repeat;
}

.pain {
  background: url("../../../public/images/emotions/emotionicons_pain.svg")
    no-repeat;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
}
