.checkin_body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 540px;
}

.checkin_body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 540px;
}

.checkin_bodyMobile {
  min-height: calc(100vh - 200px);
}

.checkin_bodyMobile {
  min-height: calc(100vh - 200px);
}

.textarea {
  width: 300px;
  margin: 7.5px !important;
}

.datetextarea {
  margin-bottom: 10px !important;
}

.checkin_submit {
  font-weight: 600;
  width: 80%;
  margin-top: auto;
}

.checkin_btn {
  text-align: center;
}

.checkin_btn_container {
  margin-top: 2em;
}
