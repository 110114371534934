h1 {
    margin: 8px;
}

.screenContainer {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
}

.item_icon {
    color: var(--grey-400);
    margin-right: 17.5px;
}

.cta_booking {
    max-width: 80%;
    text-align: center;
    font-weight: 600;
    margin-bottom: 1rem;
}