.notFound_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
  padding: 15px;
  text-align: center;
}

.notFound_container>h2 {
  padding: 15px 0;
}

.notFound_container>h3 {
  width: 80%;
}

.notFound_link {
  color: var(--blue-500);
}