.body {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--height) - 300px);
}

.body>h1 {
  text-align: center;
  margin-bottom: 20px;
}

.body>p {
  color: var(--grey-400);
  font-weight: 600;
  font-size: 18px;
}