.goalCard {
  padding: 10px;
  cursor: pointer;
  min-height: 45px;
  text-align: center;
  display: flex;
  align-items: center;
  place-content: center;
  border-radius: 20px !important;
}
