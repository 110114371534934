.pathwayIntro {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pathwayIntro_body {
  height: 490px;
  align-items: center;
}

.pathwayIntro_textField {
  width: 100%;
  margin-bottom: 15px !important;
}

.pathwayIntro_label {
  color: var(--grey-400);
  font-size: 14px;
  align-self: flex-start;
  margin-bottom: 7.5px;
}

.pathwayIntro_submit {
  margin-top: auto;
}