.chip {
  margin-right: 7px !important;
  margin-bottom: 7px !important;
  background-color: rgba(242, 114, 84, 0.2) !important;
  border: none !important;
}

.active {
  color: white !important;
  font-weight: 600;
  background-color: var(--tangerine-500) !important;
}

.btn {
  font-size: 18px !important;
  color: var(--blue-500) !important;
  background-color: rgba(11, 126, 161, 0.2) !important;
}

.btn:hover {
  background-color: rgba(11, 126, 161, 0.3) !important;
}

.btn:active {
  background-color: rgba(11, 126, 161, 0.4) !important;
}