.updateJournal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.updateJournal_btn {
  font-weight: 600;
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.updateJournal_btn_blue {
  color: var(--blue-500);
}

.updateJournal_btn_orange {
  color: var(--tangerine-500);
}

.updateJournal_header>span {
  font-weight: 700;
}

.updateJournal_body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 450px;
}

.updateJournal_field {
  margin-top: 20px !important;
}

.updateJournal_submitBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}

.updateJournal_repeat {
  display: flex !important;
  align-items: center;
}

.updateJournal_repeat>p {
  padding-right: 5px;
  font-size: 12px;
}

.textarea {
  width: 300px;
  margin: 5px !important;
}

.datetextarea {
  margin: 5px 0 !important;
}

.updateJournal_p {
  text-align: center;
  width: 80%;
}

.updateJournal_bodyMobile {
  min-height: calc(100vh - 300px);
}

.updateJournal_btns {
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
  margin-bottom: 0;
  justify-content: space-between;
}