.header {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn {
  font-weight: 600;
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.btn_blue {
  color: var(--blue-500);
}

.btn_orange {
  color: var(--tangerine-500);
}

.btnMarginBottom {
  margin-bottom: 16px;
}

.header>span {
  font-weight: 700;
}

.body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 450px;
}

.body>h3 {
  align-self: flex-start;
  margin-left: 15px;
}

.bodyMobile {
  min-height: calc(100vh - 300px);
}

.btns {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
  margin-bottom: 20px;
  justify-content: space-between;
  gap: 16px;
}

.textField {
  width: 300px;
  margin: 7.5px !important;
}

.notes {
  border-radius: 15px;
  margin-bottom: 20px;
  margin-top: 5px;
  width: 95%;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.05) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.05) 0px 8px 16px -8px;
}