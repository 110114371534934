.pathwayNote_container {
  width: 80%;
  min-height: 60px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  margin: 6px 0;
}

.pathwayNote_info {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 10px;
}

.pathwayNote_header {
  display: flex;
  align-items: center;
}

.pathwayNote_header>h3 {
  cursor: pointer;
  font-weight: 700;
  font-size: 1rem;
}

.pathwayNote_iconPathways {
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  background: url("../../../../public/images/nav_icons/pathway_grey.svg") no-repeat;
}

.pathwayNote_iconPathways:hover {
  filter: brightness(0) saturate(100%) invert(27%) sepia(61%) saturate(2736%) hue-rotate(173deg) brightness(94%) contrast(91%);
}

.pathwayNote_info>p {
  color: var(--grey-400);
  font-size: 14px;
  cursor: pointer;
}