.reviewWellness {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 15px;
  width: 85%;
  margin-top: -20px;
  margin-bottom: 20px;
}

.reviewWellness_body {
  min-height: 565px;
  align-items: center;
}

.reviewWellness_header {
  margin-top: 10px;
  width: 100%;
}

.reviewWellness_header>h3 {
  color: var(--grey-400);
  font-weight: 800;
  text-align: center;
}

.reviewWellness_info>h2 {
  font-size: 22px;
}

.reviewWellness_info>h3 {
  font-size: 18px;
  font-weight: 600;
}

.reviewWellness_info>p {
  color: var(--grey-400);
  padding: 5px 0;
  font-size: 14px;
}

.reviewWellness_info>p>span {
  font-weight: 800;
}

.reviewWellness_habits {
  width: 100%;
}

.reviewWellness_section {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.reviewWellness_section>h3::first-letter {
  text-transform: capitalize;
}

.reviewWellness_sectionList {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 15px;
  padding: 5px 15px;
}

.reviewWellness_item {
  margin: 5px 0;
}

.reviewWellness_item>p {
  color: var(--grey-400);
}

.reviewWellness_btns {
  margin-top: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.reviewWellness_btn {
  margin: 0 10px;
  font-size: medium;
  border: none;
  border-radius: 50px;
  padding: 12px 0;
  width: 120px;
  font-weight: 600;
  cursor: pointer;
}

.reviewWellness_checkin {
  display: flex;
  margin-top: 2px;
}

.reviewWellness_checkin {
  font-weight: 600;
}

.reviewWellness_checkin>p {
  color: var(--grey-400);
  margin-left: 5px;
}