.textarea {
  width: 300px;
  margin: 7.5px !important;
}

.datetextarea {
  margin-bottom: 10px !important;
}

.body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 500px;
}

.bodyMobile {
  min-height: calc(100vh - 200px);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.pathwayBtn {
  font-weight: 600;
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.pathwayBtn_blue {
  color: var(--blue-500);
}

.pathwayBtn_orange {
  color: var(--tangerine-500);
}

.p {
  text-align: center;
  width: 90%;
  margin-bottom: 10px;
  margin-top: 5px;
}

.p>span {
  color: var(--grey-400);
  font-weight: 600;
}

.submit {
  width: 80%;
  margin-top: auto;
}

.noPlan {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.noPlan_plus {
  font-size: 32px;
  margin-right: 5px;
  padding-bottom: 3px;
}

.noPlan_btn {
  color: var(--grey-400);
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.noPlan_btn:hover {
  color: var(--tangerine-400);
}