.header {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 20px;
  margin-left: 7.5px;
  margin-bottom: 10px;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.title>h3 {
  color: var(--grey-400);
}

.body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: calc(var(--height) - 110px);
}

.bodyMobile {
  min-height: calc(100vh - 200px);
}

.btn {
  color: var(--tangerine-500);
  font-weight: 700;
  font-size: 18px;
}

.info {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.infoMobile {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.progressText {
  text-align: center;
  max-width: 85%;
  font-weight: 1000;
  color: var(--tangerine-500);
}

.progressTextLarger {
  font-size: 26px;
}

.progressTextSmaller {
  font-size: 18px;
}

.progressBar {
  display: block;
}

.adjust {
  display: flex;
  align-content: center;
  justify-content: space-between;
  height: 100%;
  margin-top: 20px;
}

.adjust>button {
  border: none;
  border-radius: 50px;
  margin: 0 15px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.field {
  margin-top: 20px;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.field_add {
  font-size: 14px;
  width: 50%;
}

.manualBtn {
  margin-left: 10px;
  border: none;
  border-radius: 50px;
  width: 50px;
  height: 30px;
  cursor: pointer;
}