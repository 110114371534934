.soundItem {
    cursor: pointer;
    padding: 10px;
    margin: 5px;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    background-color: #f7f7f7;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.soundItem:hover {
    background-color: #eaeaea;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.soundItemIcon {
    margin-right: 10px;
    vertical-align: middle;
}

.textarea {
    width: 100%;
}

.navBtn {
    width: 100%;
    margin: 0px;
    margin-top: 1em;
}

.toolKitScreen {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
}

.toolKitItem_icon {
    color: var(--grey-400);
    margin-right: 17.5px;
}