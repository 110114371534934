.body {
  padding: 10px 10px;
  margin: 10px 0;
  width: 90%;
  background-color: white;
  border-radius: 7.5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
}

.item {
  font-weight: 600;
}

.item>span {
  color: var(--grey-400);
}

.btn {
  border: none;
  height: 25px;
  padding: 0 15px;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}