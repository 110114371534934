.header {
  align-self: flex-start;
  margin-left: 35px;
}

.completedHeader {
  /*completed treatments entry*/
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
}

.filler {
  align-self: flex-start;
  margin-left: 35px;
  margin-top: 5px;
  color: var(--grey-400);
}