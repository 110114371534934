html {
  background-color: #f8f7f2;
}

body {
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* SIZE */
  --width: 350px;
  --height: 650px;

  /* GLOBAL COLORS */
  /* BRAND */
  --ocean-bay: rgba(224, 248, 255, 1);

  /* NEUTRALS */
  --cream: rgb(248, 247, 242, 1);
  --white: rgb(255, 255, 255);
  --black: rgb(0, 0, 0);

  /* PRIMARY - TANGERINE */
  --tangerine-50: rgba(254, 241, 238, 1);
  --tangerine-100: rgba(251, 211, 202, 1);
  --tangerine-200: rgba(249, 190, 176, 1);
  --tangerine-300: rgba(246, 161, 140, 1);
  --tangerine-400: rgba(245, 142, 118, 1);
  --tangerine-500: rgba(242, 114, 84, 1);
  --tangerine-600: rgba(220, 104, 76, 1);
  --tangerine-700: rgba(172, 81, 60, 1);
  --tangerine-800: rgba(133, 63, 46, 1);
  --tangerine-900: rgba(102, 48, 35, 1);

  /* SECONDARY - BLUE*/
  --blue-50: rgba(231, 242, 246, 1);
  --blue-100: rgba(179, 215, 226, 1);
  --blue-200: rgba(143, 196, 212, 1);
  --blue-300: rgba(92, 169, 192, 1);
  --blue-400: rgba(60, 152, 180, 1);
  --blue-500: rgba(11, 126, 161, 1);
  --blue-600: rgba(10, 115, 147, 1);
  --blue-700: rgba(8, 89, 114, 1);
  --blue-800: rgba(6, 69, 89, 1);
  --blue-900: rgba(5, 53, 68, 1);

  /* SUCCESS - GREEN */
  --green-50: rgba(233, 246, 236, 1);
  --green-100: rgba(186, 226, 197, 1);
  --green-200: rgba(152, 212, 169, 1);
  --green-300: rgba(106, 193, 129, 1);
  --green-400: rgba(77, 181, 105, 1);
  --green-500: rgba(32, 162, 67, 1);
  --green-600: rgba(29, 147, 61, 1);
  --green-700: rgba(23, 115, 48, 1);
  --green-800: rgba(18, 89, 37, 1);
  --green-900: rgba(13, 68, 28, 1);

  /* ERROR/DANGER - RED  */
  --red-50: rgba(255, 230, 230, 1);
  --red-100: rgba(255, 176, 176, 1);
  --red-200: rgba(255, 138, 138, 1);
  --red-300: rgba(255, 84, 84, 1);
  --red-400: rgba(255, 51, 51, 1);
  --red-500: rgba(255, 0, 0, 1);
  --red-600: rgba(232, 0, 0, 1);
  --red-700: rgba(181, 0, 0, 1);
  --red-800: rgba(140, 0, 0, 1);
  --red-900: rgba(107, 0, 0, 1);

  /* GREY  */
  --grey-50: rgba(239, 239, 239, 1);
  --grey-100: rgba(206, 206, 207, 1);
  --grey-200: rgba(183, 183, 183, 1);
  --grey-300: rgba(150, 150, 150, 1);
  --grey-400: rgba(129, 129, 130, 1);
  --grey-500: rgba(98, 98, 99, 1);
  --grey-600: rgba(89, 89, 90, 1);
  --grey-700: rgba(70, 70, 70, 1);
  --grey-800: rgba(54, 54, 54, 1);
  --grey-900: rgba(41, 41, 42, 1);
}

a {
  text-decoration: none;
  color: black;
}

.app_mobileWrapper {
  height: 100vh;
  overflow: scroll;
  /* Hide Scrollbar */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.app_mobileWrapper::-webkit-scrollbar {
  display: none;
}

.app_wrapper {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.toast_body {
  width: max-content;
  height: 90px;
  transform: none;
  -webkit-transform: none;
}

.toast_container {
  position: absolute;
  display: flex;
  justify-content: center;
  width: var(--width);
}

.toast_reference_container {
  position: "relative";
  width: 0;
  height: 0;
}

.app_body {
  width: var(--width);
  height: var(--height);
  border: 2px solid var(--light_accent_grey);
  border-radius: 25px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  overflow: scroll;

  /* Hide Scrollbar */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.app_body::-webkit-scrollbar {
  display: none;
}

.app_loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
  width: 100%;
}