.add>a:hover {
  background-color: var(--tangerine-600);
}

.add>a:active {
  background-color: var(--tangerine-600);
}

.viewHistory {
  font-weight: 600;
  font-size: 14px;
  margin-top: 5px;
  color: var(--tangerine-500);
}

.viewHistory:hover {
  color: var(--tangerine-400);
}

.header {
  align-self: flex-start;
  margin-left: 35px;
  margin-top: 25px;
}

.headerFirst {
  margin-top: 5px;
}

.completedHeader {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
}

.filler {
  align-self: flex-start;
  margin-left: 35px;
  margin-top: 5px;
  color: var(--grey-400);
}

.fillerButton {
  align-self: flex-start;
  margin-left: 35px;
  color: var(--grey-400);
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.fillerButton:hover {
  color: var(--tangerine-400);
}

.fillerPlus {
  font-size: 24px;
  margin-right: 5px;
  padding-bottom: 3px;
}

.section {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mobileSection {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
}