.habitsWidget_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.habitsWidget_wrapper {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 15px;
  min-height: 175px;
  width: 85%;
  padding: 5px 2px;
}

.habitsWidget_container>h3 {
  margin-top: 20px;
  align-self: flex-start;
  margin-left: 35px;
  margin-bottom: 5px;
}

.habitsWidget_fillerButton {
  align-self: flex-start;
  margin-left: 14px;
  margin-top: 5px;
  color: var(--grey-400);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.habitsWidget_fillerButton:hover {
  color: var(--tangerine-400);
}

.habitsWidget_fillerPlus {
  font-size: 24px;
  margin-right: 5px;
  padding-bottom: 3px;
}

.habitsWidget_habit {
  width: 100%;
  min-height: 40px;
  margin-top: 10px;
  margin-left: 13px;
  display: flex;
}

.habitsWidget_habitText {
  width: 77%;
  color: black;
  cursor: pointer;
}

.habitsWidget_habitText>h3 {
  font-size: 16px;
}

.habitsWidget_habitText>span {
  color: var(--grey-400);
  font-size: 14px;
  margin-top: -2px;
}

.habitsWidget_progress {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.habitsWidget_progressBar {
  display: block;
}

.habitsWidget_progressText {
  color: var(--blue-500);
  font-weight: 800;
  font-size: 10px;
}