.header {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 7px;
}

.appointmentBtn {
  font-weight: 600;
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.appointmentBtn_blue {
  color: var(--blue-500);
}

.appointmentBtn_orange {
  color: var(--tangerine-500);
}

.header>span {
  font-weight: 700;
}

.body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 450px;
}

.field {
  margin-top: 20px !important;
}

.submitBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}

.repeat {
  display: flex !important;
  align-items: center;
}

.repeat>p {
  padding-right: 5px;
  font-size: 12px;
}

.textarea {
  width: 300px;
  margin: 5px !important;
}

.datetextarea {
  margin-top: 5px !important;
  margin-bottom: 10px;
}

.p {
  text-align: center;
  width: 80%;
}

.bodyMobile {
  min-height: calc(100vh - 300px);
}

.btns {
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 20px;
  justify-content: space-between;
}