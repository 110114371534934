.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 440px;
}

.bodyMobile {
  min-height: calc(100vh - 300px);
}

.submitBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}

.textarea,
.datetextarea,
.pathwayselect {
  width: 300px;
}

.textarea {
  margin: 16px auto !important;
}

.datetextarea {
  margin: 0 auto 16px !important;
}

.pathwayselect {
  margin: 0 auto 12px !important;
}