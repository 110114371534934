.firstParagraph {
  padding-top: 20px;
}

.onboardingParagraph {
  font-size: 18px;
  width: 70%;
  text-align: left;
  margin-top: 18px;
}

.btnContainer {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
}

.nextBtn,
.skipBtn {
  font-size: 16px;
  min-width: 0;
  max-width: 240px;
  font-weight: 400;
}

.nextBtn {
  margin-right: auto;
  padding: 10px 30px;
}

.skipBtn {
  margin: 170px 0 0 auto;
  padding: 10px 36px;
}
