.auth_header {
  margin-top: 30px;
  margin-bottom: 30px;
}

.auth_header>h1 {
  font-size: 25px;
  text-align: center;

}

.login_extraspace {
  margin-bottom: 25px;
  text-align: center;
}

.auth_wrapper>p {
  padding-top: 15px;
}

.auth_link {
  color: black;
  font-weight: bold;
}

.auth_link:hover {
  color: var(--tangerine-500);
}

.auth_form {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}


.auth_formBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
}

.auth_formBodyMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
}

.auth_btn {
  width: 100%;
  margin-bottom: 5px;
  max-width: 100%;
  min-width: 100%;
}

.auth_field {
  margin-bottom: 10px !important;
  width: 100%;
}

.profile_pic_field {
  margin-bottom: 15px !important;
}


.auth_forget_password {
  width: 100%;
  text-align: right;
  margin-bottom: 2em;
  text-decoration: underline;
  color: var(--blue-500);
}

.auth_forget_password:hover {
  cursor: pointer;
}


.background {
  height: 100vh;
  width: 100%;
  background-image: url("../../../public/images/Orientation/Login_signup_mountains.svg"),
    linear-gradient(to top, #ee9b68, #ecb683, #eccea5, #efe4ca, #f8f7f2);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 150% 125%, 100%, 100%;
  background-position: center bottom;
  overflow: auto;
}