.add_post_body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 540px;
}

.add_post_bodyMobile {
  min-height: calc(100vh - 200px);
}

.textarea {
  margin: 7.5px !important;
}

.post_submit {
  font-weight: 600;
  width: 80%;
  margin-top: 1em;
}

.imageList {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.3) 70%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
  border-radius: 20px 20px 0px 0px;
}

.imageIcon {
  color: white !important;
}

.imageUploadPaper {
  padding: 4rem !important;
  text-align: center;
  background-color: transparent !important;
}

.imageUploadPaperActive {
  padding: 1rem !important;
  text-align: left;
  background-color: transparent !important;
}

.moreImageUpload {
  border-radius: 20px !important;
  height: 165px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.journalImageContainer {
  margin-right: 10px;
}

.journalImage {
  border-radius: 20px;
  height: 160px !important;
}
