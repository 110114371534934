.header {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.pathwayBtn {
  font-weight: 600;
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.pathwayBtn_blue {
  color: var(--blue-500);
}

.pathwayBtn_orange {
  color: var(--tangerine-500);
}

.header>span {
  font-weight: 700;
}

.body {
  height: 490px;
}

.information {
  text-align: center;
  color: var(--grey-400);
  margin-bottom: 10px;
}

.field {
  margin-bottom: 20px !important;
}

.btns {
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.wellnessBtn {
  margin: 0 15px;
  font-size: medium;
  border: none;
  border-radius: 50px;
  padding: 12px 40px;
  font-weight: 600;
  cursor: pointer;
}

.submit {
  font-weight: 600;
  width: 100%;
  margin-top: auto;
  margin-bottom: 20px;
}

.repeat {
  display: flex !important;
  align-items: center;
}

.repeat>p {
  padding-right: 5px;
  font-size: 12px;
}