.btn {
  padding: 12px 6px;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  min-width: 160px;
  max-width: 160px;
  font-weight: 600;
  font-family: 'Nunito Sans';
}

.btn:hover {
  cursor: pointer;
}

.backBtn {
  color: var(--blue-600);
  font-weight: 700;
  display: flex;
  align-self: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0.5rem 1rem 0;
  margin-left: 10px;
}

/* PRIMARY BUTTON */
.primary {
  color: var(--black);
  background-color: var(--tangerine-500);
}

.primary:hover {
  color: var(--black);
  background-color: var(--tangerine-300);
}

.primary:focus,
.primary:active {
  color: var(--black);
  background-color: var(--tangerine-100);
}

.primary:disabled {
  color: var(--grey-100);
  background-color: var(--grey-800);
}

/* SECONDARY BUTTON */
.secondary {
  color: var(--black);
  background-color: transparent;
}

.secondary:hover {
  color: var(--black);
  background-color: var(--blue-100);
}

.secondary:focus,
.secondary:active {
  color: var(--black);
  background-color: var(--blue-300);
}

.secondary:disabled {
  color: var(--grey-50);
  background-color: var(--grey-800);
}

/* STATES BUTTONS */
.complete {
  color: var(--green-900);
  background-color: var(--green-100);
}

.disabled {
  color: var(--grey-900);
  background-color: var(--grey-100);
}

/* LINKS */
.links {
  background-color: var(--cream);
  padding: 1rem 0rem;
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 16px;
  border: none;
}

.primaryLink {
  color: var(--tangerine-500);
}

.primaryLink:hover {
  color: var(--tangerine-700);
}

.secondaryLink {
  color: var(--blue-500);
}

.secondaryLink:hover {
  color: var(--blue-700);
}

.dangerLink {
  color: var(--red-700);
}

/* HELPERS */
.fullWidth {
  max-width: none;
  width: 100%;
}

.marginTop {
  margin-top: 1.25rem;
}

/* CONTAINERS */
.groupColumn {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
}

.groupRow {
  display: flex;
  justify-content: space-around;
  gap: 0.5rem;
  width: 100%;
}

/* TODO: Remove unused buttons */
.journalbtn {
  padding: 40px;
  border-radius: 50px;
  border: none;
  font-size: 20px;
  min-width: 250px;
  margin-top: 30px;
  display: inline;
  margin-right: 5px;
  margin: 5px;
  justify-content: center;
  align-items: center;
  flex: auto;
}

.cognitiveDistortionsBtn {
  padding: 15px;
  border-radius: 15px;
  border: none;
  font-size: 15px;
  min-width: 250px;
  margin-top: 30px;
  display: inline;
  margin-right: 5px;
  margin: 5px;
  justify-content: center;
  align-items: center;
  font-weight: 200 !important;
  flex: auto;
}

.smallJournalBtn {
  padding: 10px;
  border-radius: 50px;
  border: none;
  font-size: 15px;
  min-width: 60px;
  margin-top: 90px;
  display: inline;
  margin-right: 5px;
  margin: 5px;
  justify-content: center;
  align-items: center;
  flex: auto;
}

.smallJournalBtn:disabled {
  background-color: #e0e0e0;
  color: black;
}

.smallActivityBtn {
  padding: 12px 24px;
  border-radius: 50px;
  border: none;
  font-size: 15px;
  min-width: 60px;
  display: inline;
  justify-content: center;
  align-items: center;
  flex: auto;
}

.journalentrybtn {
  padding: 10px;
  border-radius: 25px;
  font-size: 12px;
  height: 40px;
  min-width: 100px;
  margin-right: 5px;
  margin: 5px;
  cursor: pointer;
}

.checkinBtn {
  margin-top: 2em;
  padding: 15px;
  border-radius: 50px;
  border: none;
  font-size: 18px;
  min-width: 130px;
  max-width: 130px;
  font-weight: 600;
}

.rightjournalentrybtn {
  padding: 10px;
  border-radius: 25px;
  border: none;
  font-size: 10px;
  min-width: 80px;
  margin-top: 30px;
  display: inline;
  margin: 2px;
  justify-content: right;
  align-items: right;
  flex: auto;
  border: 1px solid grey;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 50px;
}

.left {
  display: flex;
  justify-content: left;
  margin-left: 10px;
}

.right {
  display: flex;
  justify-content: right;
  align-items: right;
}

.spacearound {
  display: flex;
  justify-content: space-around;
}

.verticalcenter {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.textcenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
}

.action_btn {
  padding: 10px;
  border-radius: 25px;
  border: none;
  font-size: 10px;
  min-width: 80px;
  margin-top: 30px;
  width: 100px;
  margin-right: 5px;
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin_backBtn {
  align-self: flex-start;
  padding: 10px 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: -5px;
}

.orange_btn {
  color: white;
  background-color: var(--tangerine-500);
}

.blue_orientation_btn {
  color: white;
  background-color: rgba(11, 126, 161, 0.8);
  min-width: 200px;
  max-width: 200px;
}

.blue_orientation_btn:hover {
  background-color: rgba(11, 126, 161, 0.2);
}

.blue_orientation_btn:active {
  background-color: rgba(11, 126, 161, 1);
}



.orange_btn:hover {
  background-color: var(--tangerine-500);
}

.orange_btn:active {
  background-color: var(--tangerine-500);
}

.white_btn:hover {
  color: white;
  background-color: var(--tangerine-500);
}

.white_btn:active {
  color: white;
  background-color: var(--tangerine-500);
}

.white_btn {
  color: black;
  background-color: var(--white);
}

.black_btn {
  color: white;
  background-color: black;
}

.black_btn:hover {
  background-color: rgb(26, 26, 26);
}

.black_btn:active {
  background-color: rgb(46, 46, 46);
}

.faded_orange {
  /* background-color: rgba(242, 114, 84, 0.2); */
  background-color: var(--tangerine-100);
  color: var(--tangerine-600);
  font-weight: 600;
  border: none;
}

.faded_orange:hover {
  /* background-color: rgba(242, 114, 84, 0.3); */
  background-color: var(--tangerine-200);
}

.faded_orange:active {
  /* background-color: rgba(242, 114, 84, 0.4); */
  background-color: var(--tangerine-200);
}

.faded_blue {
  background-color: var(--blue-200);
  color: var(--cream);
  font-weight: 600;
  border: none;
}

.faded_blue:hover {
  background-color: #218bb0;
}

.faded_blue:active {
  background-color: #218bb0;
}

.faded_black {
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  font-weight: 600;
  color: black;
}

.faded_black:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.faded_black:active {
  background-color: rgba(0, 0, 0, 0.4);
}

.faded_red {
  /* background-color: rgba(255, 59, 48, 0.2); */
  /* color: rgba(255, 59, 48); */
  background-color: var(--red-200);
  color: var(--red-600);
  border: none;
  font-weight: 600;
}

.faded_red:hover {
  /* background-color: rgba(255, 59, 48, 0.3); */
  background-color: var(--red-300);
}

.faded_red:active {
  background-color: rgba(255, 59, 48, 0.4);
}

.faded_white {
  background-color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
  border: none;
  color: black;
}

.faded_white:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.faded_white:active {
  background-color: rgba(255, 255, 255, 1);
}

.faded_green {
  /* background-color: rgba(52, 199, 89, 0.2); */
  /* color: rgb(17, 85, 34); */
  background-color: var(--green-100);
  color: var(--green-800);
  font-weight: 600;
}

.faded_green:hover {
  /* background-color: rgba(52, 199, 89, 0.3); */
  background-color: var(--green-200);
}

.faded_green:active {
  /* background-color: rgba(52, 199, 89, 0.4); */
  background-color: var(--green-200);
}

.faded_blue {
  background-color: var(--blue-100);
  color: var(--blue-800);
  /* color: rgba(25, 117, 146, 1); */
}

.faded_blue:hover {
  background-color: var(--blue-200);
}

.faded_blue:active {
  background-color: var(--blue-400);
}