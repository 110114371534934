.badges {
  display: grid;
  width: 90%;
  grid-template-columns: 1fr 1fr 1fr;
}

.badges_modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.badges_modalForm {
  background-color: var(--cream);
  width: 325px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
}

.badges_modalForm>h3 {
  margin-top: 20px;
}

.badges_modalForm>button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  font-weight: 600;
}

.badges_modalInfo {
  display: flex;
  align-items: center;
  margin: 5px 0;
  width: 80%;
}

.badges_modalInfo>p {
  color: var(--grey-400);
}

.badges_modalInfo>img {
  width: 35px;
  margin-right: 15px;
}