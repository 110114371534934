.heading {
  padding-top: 36px;
  width: 70%;
  font-weight: 600;
  font-size: 30px;
}

.onboardingParagraph {
  font-size: 18px;
  width: 70%;
  text-align: left;
  margin-top: 18px;
}

.loweronboardingParagraph {
  font-size: 18px;
  width: 70%;
  text-align: right;
  margin-top: 150px;
}

.btnContainer {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
  padding-top: 20px;
  padding-left: 250px;
}

.nextBtn {
  font-size: 16px;
  padding: 18px 55px;
  min-width: 0;
  max-width: 200px;
  font-weight: 500;
}

.logo {
  padding-bottom: 10px;
  padding-top: 10px;
}

.background {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(0deg, rgba(11,126,161,1) 0%, rgba(0,212,255,0) 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100%, 100%;
  background-position: center bottom;
  overflow: auto;
}