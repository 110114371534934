.textarea {
  resize: none;
  width: 160px;
  height: 50px;
  margin: 5px;
}

.record_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}

.wrapper {
  border-radius: 15px;
  background-color: white;
  height: auto;
  width: 85%;
  padding: 10px;
  margin: 0 25px 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.wrapper > h3 {
  align-self: flex-start;
  margin-left: 10px;
  margin-top: 5px;
}

.wrapper > p {
  align-self: flex-start;
  margin-left: 10px;
  margin-top: 5px;
}

.healthinfocomponent {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
}

.familyHistoryComponent {
  display: flex;
  gap: 1rem;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
}

.familyHistoryCondition {
  font-weight: 700;
}
