.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  border-top: 1px solid var(--grey-200);
  bottom: 0;
  background-color: var(--cream);
  border-radius: 0 0 25px 25px;
  padding: 7px 0;
  position: fixed;
}

.navMobile {
  padding-bottom: 25px;
  /* move footer into safe zone */
  position: fixed;
}

.fillerMoile {
  height: 100px;
}

.container {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  width: 85%;
  padding-bottom: 5px;
}

.icon {
  width: 30px;
  height: 30px;
}

.iconLink {
  width: 30px;
  height: 30px;
}

.iconHome {
  background: url("../../../public/images/nav_icons/home_grey.svg") no-repeat;
}

.icon:hover {
  filter: brightness(0) saturate(100%) invert(27%) sepia(61%) saturate(2736%) hue-rotate(173deg) brightness(94%) contrast(91%);
}

.iconActive {
  filter: brightness(0) saturate(100%) invert(27%) sepia(61%) saturate(2736%) hue-rotate(173deg) brightness(94%) contrast(91%);
}

.iconJournal {
  background: url("../../../public/images/nav_icons/journal_grey.svg") no-repeat;
}

.iconTodos {
  background: url("../../../public/images/nav_icons/todos_grey.svg") no-repeat;
}

.iconHabits {
  background: url("../../../public/images/nav_icons/habits_grey.svg") no-repeat;
}

.iconToolkit {
  background: url("../../../public/images/nav_icons/toolkit_grey.svg") no-repeat;
}

.iconActivity {
  background: url("../../../public/images/nav_icons/activities_grey.svg") no-repeat;
}

.iconCommunity {
  background: url("../../../public/images/nav_icons/community_grey.svg") no-repeat;
}