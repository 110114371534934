.myHealthRecordScreen {
  width: 90%;
  display: flex;
  align-items: center;

  flex-direction: column;
  margin-top: 10px;
}

.myHealthRecordItem_icon {
  color: var(--grey-400);
  margin-right: 17.5px;
}

.notificationScreen {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}