.generic_fillerMobile {
  z-index: 99;
  width: 100%;
  background-color: var(--blue-500);
  height: 80px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
}

.generic_fillerMobile2 {
  height: 60px;
}

.generic_containerMobile {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  /* min-height: calc(100vh - 300px); */
  padding-top: 20px;
  width: 100%;
}

.generic_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
}

.generic_wrapperMobile {
  width: 100%;
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  margin-bottom: 20px;
}

.generic_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 520px;
  padding-top: 10px;
}

.errorMessage {
  color: red !important;
  text-align: center;
}

.simpleErrorMessage {
  color: red !important;
}