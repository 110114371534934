.textarea {
  resize: none;
  width: 160px;
  height: 50px;
  margin: 5px;
}

.datetextarea {
  margin: 30px;
}
