.journalEntry_add>a:hover {
  background-color: var(--tangerine-600);
}

.journalEntry_add>a:active {
  background-color: var(--tangerine-600);
}

.journalEntry_viewHistory {
  font-weight: 600;
  font-size: 14px;
  margin-top: 5px;
  color: var(--tangerine-500);
}

.journalEntry_viewHistory:hover {
  color: var(--tangerine-400);
}

.journalEntry_header {
  margin-bottom: 10px;
  margin-top: 10px;
}

.journalEntry_headerFirst {
  margin-top: 0;
}

.journalEntry_filler {
  align-self: flex-start;
  margin-left: 35px;
  margin-top: 5px;
  color: var(--grey-400);
}

.journalEntry_fillerButton {
  align-self: flex-start;
  margin-left: 35px;
  color: var(--grey-400);
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.journalEntry_fillerButton:hover {
  color: var(--tangerine-400);
}

.journalEntry_fillerPlus {
  font-size: 24px;
  margin-right: 5px;
  padding-bottom: 3px;
}

.journalEntry_section {
  width: 100%;
}

.journalEntry_mobileSection {
  width: 100%;
  min-height: 50vh;
}