.frequency {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 7.5px 10px;
}

.frequencyChip {
  margin-right: 9px !important;
  margin-bottom: 9px !important;
  background-color: rgba(242, 114, 84, 0.2) !important;
  border: none !important;
}

.frequencyAdd {
  cursor: pointer;
  font-size: 18px !important;
  color: var(--blue-500) !important;
  background-color: rgba(11, 126, 161, 0.2) !important;
  margin-bottom: 7px !important;
}

.frequencyAdd:hover {
  background-color: rgba(11, 126, 161, 0.3) !important;
}

.frequencyAdd:active {
  background-color: rgba(11, 126, 161, 0.4) !important;
}

.frequencySelected {
  color: white !important;
  font-weight: 600;
  background-color: var(--tangerine-500) !important;
}