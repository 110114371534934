.container {
  width: 85%;
  min-height: 70px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  margin-left: 15px;
  padding: 5px 0;
}

.info {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 5px 0;
}

.info>h3 {
  cursor: pointer;
  font-weight: 700;
  font-size: 1rem;
}

.info>p {
  color: var(--grey-400);
  font-size: 14px;
  cursor: pointer;
}