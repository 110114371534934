.datetextarea {
  margin: 10px;
}

.body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 450px;
}

.bodyMobile {
  min-height: calc(100vh - 300px);
}
