.chatScreenBody {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.chatBar {
  top: auto;
  left: auto;
  right: auto;
  background-color: white !important;
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.01),
    0px 4px 5px 0px rgba(0, 0, 0, 0), 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
}

.chatBarItem {
  padding-left: 0px !important;
  min-height: auto !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: black !important;
}

.chatUserName {
  font-size: 1rem !important;
}

.chatUserStatus {
  font-size: 0.8rem !important;
}

.chatUserStatusOnline {
  color: #008000;
}

.chatInputContainer {
  padding: 1em 0.5em;
  border-radius: 20px 20px 0px 0px;
  background-color: white;
}

.chatInputPaper {
  display: flex;
  border-radius: 20px !important;
  align-items: center;
  width: 100%;
}
