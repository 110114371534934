.activeReaction {
  background-color: #0b7ea1 !important;
  border: 1px solid #0b7ea1 !important;
  color: white !important;
}

.inActiveReaction {
  background-color: #f8f7f2 !important;
  border: 1px solid #f5efd3 !important;
  color: inherit !important;
}

.activeReaction:hover {
  background-color: #0b7ea1;
  border: 1px solid #0b7ea1;
  color: white;
}

.inActiveReaction:hover {
  background-color: #f8f7f2;
  border: 1px solid #f8f7f2;
  color: inherit;
}

.reactionBtn {
  border-radius: 0px !important;
}

.reachOutContainer {
  padding: 6px 12px;
  background-color: antiquewhite;
}

.teamLink {
  font-weight: bold;
  cursor: pointer;
}

.journalImageContainer {
  margin-right: 10px;
}

.journalImage {
  border-radius: 20px;
  object-fit: cover;
  max-height: 160px;
}

.progress {
  width: 55px;
  height: 55px;
}

.progressText {
  font-size: 18px;
  font-weight: 1000;
  color: var(--tangerine-500);
}

.progressBar {
  display: block;
}