.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.btn {
  font-weight: 600;
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.btn_blue {
  color: var(--blue-500);
}

.btn_orange {
  color: var(--tangerine-500);
}

.header>span {
  font-weight: 700;
}

.body {
  height: 550px;
}

.field {
  margin-top: 20px !important;
}

.submit {
  font-weight: 600;
  width: 100%;
  margin-bottom: 10px;
  margin-top: auto;
  margin-right: 10px;
}

.submitBtns {
  margin-top: auto;
  margin-bottom: 20px;
}

.repeat>p {
  font-size: 12px;
}

.days {
  margin-left: -10px;
}

.daysMobile {
  margin-left: 0;
}

.frequency>p {
  font-size: 12px;
  margin-bottom: 5px;
}

.goalAmount {
  width: 100%;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalForm {
  background-color: var(--cream);
  width: 300px;
  height: 225px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modalForm>h3 {
  margin-top: 20px;
}

.modalForm>button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  font-weight: 600;
}

.wellnessBtns {
  margin-top: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.wellnessBtn {
  margin: 0 15px;
  font-size: medium;
  border: none;
  border-radius: 50px;
  padding: 12px 40px;
  font-weight: 600;
  cursor: pointer;
}

.addWellness {
  color: var(--grey-400);
  text-align: center;
  margin-bottom: -25px;
}

.addWellness>span {
  font-weight: 800;
}

.notifToggleCard {
  padding: 20px 10px;
  width: 100%;
}