.indicationIcon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.levelDifficult {
  background: url("../../../public/images/activity_icons/activity/activitiesicon_difficult.svg")
    no-repeat;
}

.levelMedium {
  background: url("../../../public/images/activity_icons/activity/activitiesicon_moderate.svg")
    no-repeat;
}

.levelEasy {
  background: url("../../../public/images/activity_icons/activity/activitiesicon_easy.svg")
    no-repeat;
}

.durationLong {
  background: url("../../../public/images/activity_icons/activity/activitiesicon_long.svg")
    no-repeat;
}

.durationMedium {
  background: url("../../../public/images/activity_icons/activity/activitiesicon_medium.svg")
    no-repeat;
}

.durationShort {
  background: url("../../../public/images/activity_icons/activity/activitiesicon_short.svg")
    no-repeat;
}
