.textarea {
  resize: none;
  width: 160px;
  height: 50px;
  margin: 5px;
}

.datetextarea {
  margin: 30px;
}

.body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 450px;
}

.bodyMobile {
  min-height: calc(100vh - 300px);
}

.inputArea {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}
