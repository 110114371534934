.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.pathwayBtn {
  font-weight: 600;
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.pathwayBtn_blue {
  color: var(--blue-500);
}

.pathwayBtn_orange {
  color: var(--tangerine-500);
}

.header>span {
  font-weight: 700;
}

.body {
  height: 530px;
}

.textField {
  width: 100%;
  margin-bottom: 15px !important;
}

.label {
  color: var(--grey-400);
  font-size: 14px;
  align-self: flex-start;
  margin-bottom: 7.5px;
}

.btns {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.submit {
  margin: 10px 0;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalForm {
  background-color: var(--cream);
  width: 300px;
  height: 390px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modalForm>h3 {
  margin-top: 20px;
}

.modalForm>p {
  width: 80%;
  text-align: center;
  color: var(--grey-400);
  font-weight: 600;
  margin-bottom: 7.5px;
}

.modalForm>p>span {
  font-weight: 800;
  font-style: italic;
}

.modalForm>button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}