.card {
  padding: 20px;
  margin: 7.5px 0;
  width: 100%;
  background-color: white;
  border-radius: 7.5px;
  box-shadow: rgba(50, 50, 93, 0.05) 0px 13px 27px -5px, rgba(0, 0, 0, 0.1) 0px 8px 16px -8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card>p {
  margin-left: 17.5px;
  font-weight: 800;
}