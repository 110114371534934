.toolKitScreen {
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.toolKitItem_icon {
  color: var(--grey-400);
  margin-right: 17.5px;
}