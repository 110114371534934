.boxItem {
  padding: 20px 0;
  margin: 7.5px 0;
  width: 100%;
  background-color: white;
  border-radius: 7.5px;
  box-shadow: rgba(50, 50, 93, 0.05) 0px 13px 27px -5px, rgba(0, 0, 0, 0.1) 0px 8px 16px -8px;
  display: flex;
  justify-content: space-between;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.boxItem:hover {
  transform: scale(1.1);
}

.boxItem>p {
  margin-left: 17.5px;
  font-weight: 800;
}