.container {
  width: 80%;
  min-height: 60px;
  border-radius: 10px;
  background-color: white;
  align-items: center;
  margin: 6px 0;
}

.info {
  margin: 10px;
}

.info>h3 {
  cursor: pointer;
  font-size: 22px;
  font-weight: 700;
}

.info>p {
  color: var(--grey-400);
  font-size: 14px;
  cursor: pointer;
}

.progress {
  width: 55px;
  height: 55px;
}

.progressText {
  font-size: 18px;
  font-weight: 1000;
  color: var(--tangerine-500);
}

.progressBar {
  display: block;
}

.journalImageContainer {
  margin-right: 10px;
}

.journalImage {
  border-radius: 20px;
  object-fit: cover;
  max-height: 160px;
}

.entryTitle {
  margin-bottom: 10px;
}

.entrySubTitle {
  margin-bottom: 10px;
}