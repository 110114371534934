.header {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: calc(var(--height) - 110px);
}

.bodyMobile {
  min-height: calc(100vh - 200px);
}

.btn {
  font-weight: 600;
}

.btn_blue {
  color: var(--blue-200);
}

.btn_orange {
  color: var(--tangerine-500);
}

.info {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.infoMobile {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.infoBody {
  display: flex;
  margin: 10px 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.progressText {
  text-align: center;
  max-width: 85%;
  font-weight: 1000;
  color: var(--tangerine-500);
}

.progressTextLarger {
  font-size: 18px;
}

.progressTextSmaller {
  font-size: 14px;
}

.progressBar {
  display: block;
}

.adjust {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  height: 100%;
}

.adjust>button {
  border: none;
  border-radius: 50px;
  margin: 3px 15px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.field {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.field>div {
  width: 80%;
}

.field_add {
  font-size: 14px;
  width: 50%;
}

.manualBtn {
  margin-left: 10px;
  border: none;
  border-radius: 50px;
  font-size: 12px;
  width: 45px;
  height: 25px;
  cursor: pointer;
}

.header_info {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 15px;
}

.header_infoText {
  display: flex;
  font-size: 15px;
  align-items: center;
  color: var(--grey-400);
}

.streaks {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.header_check {
  color: rgb(45, 233, 92);
  padding-right: 2px;
}

.header_balance {
  color: rgb(45, 133, 233);
  padding-right: 2px;
}

.header_star {
  color: rgb(255, 204, 0);
  padding-right: 2px;
}

.header_fire {
  color: var(--tangerine-500);
  padding-right: 2px;
}

.history {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.history_header {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.history_header>h3 {
  font-size: 24px;
}

.history_body {
  width: 100%;
  margin-bottom: 20px;
}

.history_empty {
  color: var(--grey-400);
  margin-top: 5px;
  align-self: start;
  margin-left: 9px;
}