.undo {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 20%;
  border-radius: 50px;
  align-self: center;
  pointer-events: auto;
  padding: 8px 4px;
  pointer-events: auto;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  visibility: visible;
  position: sticky;
  bottom: 100px;
}

.undo_hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  display: none;
}

.undo > span {
  margin-left: 2px;
  font-weight: 600;
}
