.habits_section {
  margin-top: 20px;
  min-width: 80%;
}

.habits_section>h2 {
  font-size: 18px;
}

.habits_none {
  display: flex;
  align-items: center;
  color: var(--grey-400);
  cursor: pointer;
  font-size: 14px;
}

.habits_none:hover {
  color: var(--tangerine-500);
}

.habits_nonePlus {
  font-size: 24px;
  padding-right: 5px;
  margin-left: 5px;
}