.outcomeList_intro {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.outcomeList_btns {
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.outcomeList_btn {
  margin: 0 15px;
  font-size: medium;
  border: none;
  border-radius: 50px;
  padding: 12px 40px;
  font-weight: 600;
  cursor: pointer;
}

.outcomeList_body {
  height: 490px;
  align-items: center;
}

.outcomeList_info {
  color: var(--grey-400);
  margin-bottom: 5px;
}

.outcomeList_info>span {
  font-weight: 800;
  font-style: italic;
}

.outcomeList_list {
  width: 100%;
}

.outcomeList_textField {
  width: 100%;
  margin-bottom: 10px !important;
  margin-top: 5px !important;
}

.outcomeList_submit {
  margin-bottom: 20px;
}

.outcomeListItem {
  display: flex;
  align-items: center;
  width: 95%;
  justify-content: space-between;
  background-color: white;
  padding: 7.5px 10px;
  margin: 5px 0;
  border-radius: 10px;
  color: var(--blue-500);
}

.outcomeListItem>h4 {
  font-weight: 800;
  text-transform: capitalize;
}

.outcomeListItem>span {
  color: var(--grey-400);
  font-weight: 800;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.outcomeListItem>span:hover {
  transform: scale(1.3);
}

.outcomeList_modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.outcomeList_modalForm {
  background-color: var(--cream);
  width: 300px;
  height: 210px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.outcomeList_modalForm>h3 {
  margin-top: 20px;
}

.outcomeList_modalForm>p {
  width: 80%;
  text-align: center;
  color: var(--grey-400);
  font-weight: 600;
}

.outcomeList_modalForm>p>span {
  font-weight: 800;
  font-style: italic;
}

.outcomeList_modalForm>button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-weight: 600;
}