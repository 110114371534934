.sideNav_container {
  position: absolute;
  z-index: 101;
  display: flex;
  flex-direction: column;
  transform: translateX(-110%);
  background-color: var(--cream);
  width: 65%;
  max-width: 300px;
  min-height: 100vh;
  height: 100%;
  transition: transform 0.3s ease-in-out;
}

.sideNav__containerMobile {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0px;
}

.sideNav_active {
  transform: translateX(-1%);
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  outline: 100vmax solid rgba(0, 0, 0, 0.3);
}

.sideNav_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 15px;
  height: 100%;
}

.sideNav_wrapperMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sideNav_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  padding-bottom: 10px;
}

.sideNav_headerMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  width: 100%;
  margin-bottom: 15px;
  background-color: var(--blue-500);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.sideNav_header>span {
  cursor: pointer;
  font-size: 24px;
  margin-top: -5px;
  color: var(--grey-400);
}

.sideNav_headerMobile>span {
  font-size: 32px;
  padding: 8px 0;
  padding-right: 20px;
  margin-top: -2px;
  color: white;
}

.sideNav_headerMobile>h3 {
  margin-left: 25px;
  color: white;
}

.sideNav_header>h3 {
  margin-left: 5px;
}

.sideNav_list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sideNav_item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.sideNav_itemMobile {
  display: flex;
  align-items: center;
}

.sideNav_item:hover>.sideNav_itemName,
.sideNav_item:hover>.sideNav_listIcon {
  color: var(--blue-500);
}

.sideNav_item_btn {
  margin-left: 10px !important;
}

.sideNav_item_btn:hover {
  background-color: white;
}

.sideNav_listIcon {
  color: var(--grey-400);
  margin-right: 5px;
}

.sideNav_itemName {
  font-size: 16px;
  color: var(--grey-400);
}

.sideNav_profile {
  color: var(--grey-400);
}

.sideNav_profileMobile {
  color: var(--tangerine-500);
}

.sideNav_profile:hover {
  color: var(--tangerine-500);
}

.sideNav_iconActive {
  color: var(--blue-500);
}

.sideNav_shareIcon {
  margin-bottom: 40px;
}

.sideNav_feedbackIcon {
  margin-top: auto;
}

.sideNav_itemActive {
  color: var(--blue-500) !important;
}

.sideNav_icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.sideNav_item:hover .sideNav_icon {
  filter: brightness(0) saturate(100%) invert(27%) sepia(61%) saturate(2736%) hue-rotate(173deg) brightness(94%) contrast(91%);
}

.sideNav_iconActive {
  filter: brightness(0) saturate(100%) invert(27%) sepia(61%) saturate(2736%) hue-rotate(173deg) brightness(94%) contrast(91%);
}

.sideNav_iconHome {
  background: url("../../../public/images/nav_icons/home_grey.svg") no-repeat;
}

.sideNav_iconJournal {
  background: url("../../../public/images/nav_icons/journal_grey.svg") no-repeat;
}

.sideNav_iconTodos {
  background: url("../../../public/images/nav_icons/todos_grey.svg") no-repeat;
  margin-left: -2px;
  margin-right: 7px;
}

.sideNav_iconHabits {
  background: url("../../../public/images/nav_icons/habits_grey.svg") no-repeat;
  margin-left: -2px;
  margin-right: 7px;
}

.sideNav_iconActivities {
  background: url("../../../public/images/nav_icons/activities_grey.svg") no-repeat;
  margin-left: -2px;
  margin-right: 7px;
}

.sideNav_iconPathway {
  background: url("../../../public/images/nav_icons/pathway_grey.svg") no-repeat;
  margin-left: -1px;
  margin-right: 6px;
}

.sideNav_iconToolkit {
  background: url("../../../public/images/nav_icons/toolkit_grey.svg") no-repeat;
  margin-left: -1px;
  margin-right: 6px;
}

.sideNav_iconBadges {
  background: url("../../../public/images/nav_icons/badges_grey.svg") no-repeat;
}

.sideNav_iconMyHealthRecord {
  background: url("../../../public/images/nav_icons/my_health_record_grey.svg") no-repeat;
  margin-left: -2px;
  margin-right: 7px;
}

.sideNav_iconFeedback {
  background: url("../../../public/images/nav_icons/feedback_grey.svg") no-repeat;
}

.sideNav_iconAdmin {
  background: url("../../../public/images/nav_icons/admin_grey.svg") no-repeat;
}

.sideNav_iconShare {
  background: url("../../../public/images/nav_icons/share_grey.svg") no-repeat;
}

.sideNav_iconSettings {
  background: url("../../../public/images/nav_icons/setting_grey.svg") no-repeat;
  margin-left: -2px;
  margin-right: 7px;
}

.sideNav_iconInsight {
  background: url("../../../public/images/nav_icons/insight_grey.svg") no-repeat;
  margin-left: -2px;
  margin-right: 7px;
}

.sideNav_iconCommunity {
  background: url("../../../public/images/nav_icons/community_grey.svg") no-repeat;
  margin-left: -2px;
  margin-right: 7px;
}

.sideNav_iconBooking {
  background: url("../../../public/images/nav_icons/calendar_grey.svg") no-repeat;
  margin-left: -2px;
  margin-right: 7px;
}