.addFab {
  width: 100%;
  position: sticky;
  bottom: 60px;
  pointer-events: none;
}

.addFab_mobile {
  bottom: 80px;
}

.addFab_plus {
  pointer-events: auto;
  float: right;
  margin-right: 20px;
  margin-bottom: 10px;
  border: none;
  border-radius: 50px;
  color: white;
  background-color: var(--blue-500);
  height: 55px;
  width: 55px;
  font-size: 52px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}