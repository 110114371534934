.header {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.toDoBtn {
  font-weight: 600;
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.toDoBtn_blue {
  color: var(--blue-500);
}

.toDoBtn_orange {
  color: var(--tangerine-500);
}

.header>span {
  font-weight: 700;
}

.field {
  margin-top: 20px !important;
}

.btns {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
  justify-content: space-between;
}