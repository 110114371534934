.datetextarea {
  margin-bottom: 10px !important;
}

.journal_body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 540px;
}

.journal_bodyMobile {
  min-height: calc(100vh - 200px);
}

.journal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.journal_btn {
  font-weight: 600;
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.journal_btn_blue {
  color: var(--blue-500);
}

.journal_btn_orange {
  color: var(--tangerine-500);
}

.journal_submit {
  font-weight: 600;
  width: 80%;
  margin-top: auto;
}

.imageUploadPaper {
  padding: 4rem !important;
  text-align: center;
  background-color: transparent !important;
}

.imageUploadPaperActive {
  padding: 2rem !important;
  text-align: left;
  background-color: transparent !important;
}

.moreImageUpload {
  border-radius: 20px !important;
  height: 165px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.journalImageContainer {
  margin-right: 10px;
}

.journalImage {
  border-radius: 20px;
  object-fit: cover;
  height: 165px !important;
}

.imageList {
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.3) 70%,
      rgba(0, 0, 0, 0) 100%) !important;
  border-radius: 20px 20px 0px 0px;
}

.imageIcon {
  color: white;
}