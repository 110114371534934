.toolKitScreen {
  width: 90%;
  display: flex;
  align-items: center;

  flex-direction: column;
  margin-top: 10px;
}

.toolKitItem_icon {
  color: var(--grey-400);
  margin-right: 17.5px;
}

.quickCheckHeadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.quickCheckContainer {
  padding: 0.5em;
  margin-top: 0.5em;
}

.muiSwitch,
.MuiFormControlLabel-label {
  font-size: 12px !important;
}