.individualPathway {
  width: 85%;
  min-height: 190px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12.5px 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.header {
  width: 90%;
}

.body {
  padding: 0 20px;
  width: 90%;
}

.body>p {
  color: var(--grey-400);
  padding: 5px 0;
  font-size: 14px;
}

.body>p>span {
  font-weight: 800;
}

.sectionBody {
  box-shadow: rgba(50, 50, 93, 0.5) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 5px 3px;
}

.fillerBtn {
  align-self: flex-start;
  margin-left: 14px;
  margin-top: 5px;
  color: var(--grey-400);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.fillerBtn:hover {
  color: var(--tangerine-400);
}

.fillerPlus {
  font-size: 24px;
  margin-right: 5px;
  padding-bottom: 3px;
}

.backBtn {
  align-self: flex-start;
  padding: 10px 30px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-top: 10px;
}

.individualPathwayBtns {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.btn {
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 50px;
  border: none;
  font-size: 18px;
  width: 102%;
  cursor: pointer;
}