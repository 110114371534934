.feedback {
  font-size: 14px;
  width: 65%;
  text-align: center;
  margin-bottom: 20px;
}

.textField {
  width: 80%;
}
