.profile_form {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.profile_formMobile {
  min-height: calc(100vh - 215px);
}

.profile_form>h2 {
  margin: 10px 0;
}

.profile_field {
  margin-bottom: 10px !important;
}

.profile_btns>button {
  margin-top: 10px !important;
}

.profile_btns {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
}

.fullwidth_btn {
  min-width: 360px;
}

h3 {
  text-transform: uppercase;
}

.h3_margin {
  margin: 2rem 0 0.5rem;
}

.h4_margin {
  font-weight: 800;
  margin: 1.75rem 0 0.5rem;
}

.settingItem_icon {
  color: var(--grey-500);
  padding-right: 0.75rem;
}

.companyLinkBox {
  padding: 0;
  gap: 0;
}

.companyLinks {
  padding: 0.75rem 0;
}

.requestDelete {
  text-align: left;
  font-weight: 600;
  background-color: transparent;
}