.navbar {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  background-color: var(--cream);
  z-index: 100;
  border-radius: 25px 25px 0 0;
  position: fixed;
  width: 100%;
  height: 40px;
}

.navbar_mobile {
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 15px;
  position: fixed;
  top: 0;
  background-color: var(--blue-500);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.navbar_container {
  width: 100%;
  position: relative;
  height: 65px;
}

.navbar_containerMobile {
  width: 100%;
}

.navbar_icon {
  color: var(--grey-200);
  cursor: pointer;
  padding-right: 25px;
  padding-left: 25px;
}

.navbar_iconMobile {
  color: white;
}

.navbar_filler_mobile {
  height: 66px;
}

.navbar_filler {
  height: 6em;
}