.emotions {
  width: 300px;
  margin: 16px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2px;
}

.emotionChip {
  font-size: 16px;
  font-weight: 600;
  color: var(--black) !important;
  background-color: var(--blue-100) !important;
  margin: 0 9px 9px 0 !important;
  border: none !important;
}

.emotionAdd {
  font-size: 18px !important;
  font-weight: 600;
  color: var(--white) !important;
  background-color: var(--tangerine-500) !important;
  cursor: pointer;
}

.emotionAdd:hover,
.emotionAdd:active {
  background-color: var(--tangerine-700) !important;
}

.emotionsSelected {
  color: white !important;
  background-color: var(--blue-600) !important;
}