.textarea {
  resize: none;
  width: 140px;
  height: 40px;
  margin: 5px;
  padding: 0.75rem;
}

.datetextarea {
  margin: 10px;
}

.body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 450px;
}

.bodyMobile {
  min-height: calc(100vh - 300px);
}

.inputArea {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}

.filterSelect {
  width: 80px;
  height: 40px;
  border-radius: 80px;
}
