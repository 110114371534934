.emotion_icon {
  width: 30px;
  height: 30px;
}

.happy {
  background: url("../../../public/images/emotions/emotionicons_happy.svg")
    no-repeat;
}

.energized {
  background: url("../../../public/images/emotions/emotionicons_energized.svg")
    no-repeat;
}

.medium {
  background: url("../../../public/images/emotions/emotionicons_tired.svg")
    no-repeat;
}

.stressed {
  background: url("../../../public/images/emotions/emotionicons_stressed.svg")
    no-repeat;
}

.sad {
  background: url("../../../public/images/emotions/emotionicons_sad.svg")
    no-repeat;
}
