.toolKitScreen {
  width: 90%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  flex-direction: column;
  margin-top: 10px;
}

.toolKitItem_icon {
  color: var(--grey-400);
  margin-right: 17.5px;
}

.centerAnimation {
  width: 90%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  flex-direction: column;
  margin-top: 50px;
}