.heading {
  width: 70%;
  text-align: center;
  font-weight: 500;
}

.btnContainer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
}

.centerAnimation {
  width: 90%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  flex-direction: column;
  margin: 6px 0 16px;
}

.tutorialVideo {
  width: 70%;
  height: auto;
}

.nextBtn {
  margin: 0 0 16px auto;
  font-size: 15px;
  padding: 10px 36px;
  min-width: 0;
  max-width: 200px;
  font-weight: 400;
}
