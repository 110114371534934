.pathway {
  width: 85%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.pathway_header {
  width: 90%;
}

.pathway_body {
  padding: 0 20px;
  width: 90%;
}

.pathway_body>p {
  color: var(--grey-400);
  padding: 5px 0;
  font-size: 14px;
}

.pathway_body>p>span {
  font-weight: 800;
}

.pathway>button {
  margin-top: 15px;
  margin-bottom: 5px;
}