.card {
    border-radius: 15px !important;
}

.cardContent {
    padding-bottom: 4px;
    cursor: pointer;
}

.subtitle {
    color: grey;
    font-size: 0.8rem !important;
}

.loadMoreBtn {
    width: 100%;
    margin: 0px;
    margin-top: 1em;
}

.textarea {
    width: 100%;
}

.backBtn {
    color: var(--tangerine-500);
}

.selected {
    background-color: #bed5e1 !important;
}

.bold {
    font-weight: bold;
}

.situationIcon {
    background: url("../../../public/images/cognitive-distortions/cognitive_situation.svg") no-repeat;
}

.thoughtIcon {
    background: url("../../../public/images/cognitive-distortions/cognitive_thoughts.svg") no-repeat;
}

.icon {
    width: 40px;
    height: 40px;
}

.paper {
    border: none !important;
}

.link {
    cursor: pointer;
}

.flexDisplay {
    display: flex;
}

.rightAlign {
    text-align: right;
}

.centerAlign {
    text-align: center;
}

.marginTop {
    margin-top: 1em !important;
}

h3 {
    text-transform: none;
    margin-top: 0.25rem;
    font-size: 1.0625rem;
}

.selectedExperimentAnswers {
    margin-top: 0.5rem;
}

.experimentBox {
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 40%;
}

.experimentBox h2 {
    margin: 0;
}

.experimentBoxBtn {
    margin: 0;
    height: 48px;
    width: 25vw;
}