.btn {
  font-weight: 600;
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.btn_orange {
  color: var(--tangerine-500);
}

.btn_blue {
  color: var(--blue-500);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.header>span {
  font-weight: 700;
}

.communityCard {
  padding: 10px;
  border-radius: 15px !important;
  cursor: pointer;
  min-height: 95px;
}

.emptyCommunityCard {
  padding: 8px;
  border-radius: 15px !important;
  min-height: 45px;
}

.emptyCommunityImage {
  height: 100px;
  width: 100px;
}

.emptyCommunitySearchText {
  color: rgb(11, 126, 161);
  font-weight: bold;
  margin-left: 2px;
}

.memberTypo {
  margin-top: 1em;
  color: #bab5b6;
}

.searchBar {
  display: flex;
  justify-content: space-between;
  border-radius: 15px !important;
}