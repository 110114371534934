.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.header>h3 {
  margin-right: 15px;
}

.btn {
  color: var(--tangerine-500);
  font-weight: 600;
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.progress {
  color: var(--tangerine-500);
  font-weight: 800;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalForm {
  background-color: var(--cream);
  width: 300px;
  height: 225px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modalForm>h3 {
  margin-top: 20px;
}

.modalForm>p {
  width: 80%;
  text-align: center;
  color: var(--grey-400);
  font-weight: 600;
}

.modalForm>p>span {
  font-weight: 800;
  font-style: italic;
}

.modalForm>button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  font-weight: 600;
}