.container {
  width: 80%;
  min-height: 50px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  margin: 6px 0;
}

.info {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.header {
  display: flex;
  align-items: center;
}

.header>h3 {
  cursor: pointer !important;
  font-weight: 700;
  font-size: 1rem;
}

.headerCompleted {
  color: var(--grey-400);
}

.iconPathways {
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  background: url("../../../public/images/nav_icons/pathway_grey.svg") no-repeat;
}

.iconPathways:hover {
  filter: brightness(0) saturate(100%) invert(27%) sepia(61%) saturate(2736%) hue-rotate(173deg) brightness(94%) contrast(91%);
}

.container>button {
  width: 25px;
  height: 25px;
  border: 2px solid var(--blue-500);
  background-color: white;
  border-radius: 15px;
  margin: 0 15px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.container>button:hover {
  background-color: var(--blue-300);
}

.info>p {
  color: var(--grey-400);
  font-size: 14px;
}

.completedButton {
  background-color: var(--blue-300) !important;
}

.completedButton:hover {
  background-color: var(--blue-400) !important;
}

.completedInfo>h3 {
  color: var(--grey-400);
}