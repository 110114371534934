.additionalNotesHistoryScreen {
  width: 90%;
  display: flex;
  align-items: center;

  flex-direction: column;
  margin-top: 10px;
}

.additionalNotesHistoryScreen_add>a:hover {
  background-color: var(--tangerine-600);
}

.additionalNotesHistoryScreen_add>a:active {
  background-color: var(--tangerine-600);
}

.additionalNotesHistoryScreen_viewHistory {
  font-weight: 600;
  font-size: 14px;
  margin-top: 5px;
  color: var(--tangerine-500);
}

.additionalNotesHistoryScreen_viewHistory:hover {
  color: var(--tangerine-400);
}

.additionalNotesHistoryScreen_header {
  align-self: flex-start;
  margin-left: 35px;
  margin-top: 0px;
}

.additionalNotesHistoryScreen_headerFirst {
  margin-top: 0;
}

.additionalNotesHistoryScreen_completedHeader {
  /*completed entry*/
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
}

.additionalNotesHistoryScreen_filler {
  align-self: flex-start;
  margin-left: 35px;
  margin-top: 5px;
  color: var(--grey-400);
}

.additionalNotesHistoryScreen_fillerButton {
  align-self: flex-start;
  margin-left: 35px;
  color: var(--grey-400);
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.additionalNotesHistoryScreen_fillerButton:hover {
  color: var(--tangerine-400);
}

.additionalNotesHistoryScreen_fillerPlus {
  font-size: 24px;
  margin-right: 5px;
  padding-bottom: 3px;
}

.additionalNotesHistoryScreen_section {
  width: 100%;
}

.additionalNotesHistoryScreen_mobileSection {
  width: 100%;
  min-height: 50vh;
}