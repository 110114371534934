.firstParagraph {
  padding-top: 36px;
}

.stepDescription {
  margin: 24px auto 0;
  width: 80%;
  font-size: 16px;
  text-align: left;
}

.paragraph {
  margin-bottom: 18px;
}

.additionalNoteContainer_body {
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 240px;
  padding: 0 36px 16px;
  gap: 10px;
}

.additionalNoteContainer_bodyMobile {
  min-height: calc(100vh - 300px);
}

.btnContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 80%;
  margin: auto 0 10px;
}

.btnGroup {
  margin-right: auto;
}

.nextBtn,
.skipBtn,
.submitBtn {
  font-size: 16px;
  min-width: 130px;
  max-width: 200px;
  padding: 10px 20px;
  font-weight: 400;
}

.nextBtn,
.skipBtn {
  margin-left: auto;
}

.skipBtn {
  text-decoration: underline;
  border: none;
  background: none;
  cursor: pointer;
}

.submitBtn {
  min-width: 150px;
}

.textField {
  width: 100%;
}
