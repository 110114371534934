.header {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.toDoBtn {
  font-weight: 600;
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.toDoBtn_blue {
  color: var(--blue-500);
}

.toDoBtn_orange {
  color: var(--tangerine-500);
}

.header>span {
  font-weight: 700;
}

.body {
  height: 490px;
}

.field {
  margin-top: 20px !important;
}

.submit {
  font-weight: 600;
  width: 100%;
  margin-top: auto;
}

.repeat {
  display: flex !important;
  align-items: center;
}

.repeat>p {
  padding-right: 5px;
  font-size: 12px;
}