.toDosWidget_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toDosWidget_wrapper {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 15px;
  min-height: 175px;
  width: 85%;
  padding: 5px 2px;
}

.toDosWidget_container>h3 {
  margin-top: 20px;
  align-self: flex-start;
  margin-left: 35px;
  margin-bottom: 5px;
}

.toDosWidget_toDo {
  width: 90%;
  margin: 10px 15px;
  display: flex;
  align-items: center;
}

.toDosWidget_toDoTitle {
  font-weight: 600;
  color: black;
  font-size: 0.9rem;
}

.toDosWidget_toDoWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toDosWidget_toDoWrapper>span {
  color: var(--grey-400);
  font-size: 12px;
}

.toDosWidget_toDo>button {
  width: 25px;
  height: 25px;
  border: 2px solid var(--blue-500);
  background-color: var(--blue-500);
  border-radius: 15px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.toDosWidget_toDo>button:hover {
  background-color: var(--blue-500);
}

.toDosWidget_fillerButton {
  align-self: flex-start;
  margin-left: 17px;
  color: var(--grey-400);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.toDosWidget_fillerButton:hover {
  color: var(--tangerine-400);
}

.toDosWidget_fillerPlus {
  font-size: 24px;
  margin-right: 5px;
  padding-bottom: 3px;
}

.toDosWidget_repeat {
  margin-left: 5px;
}

.toDosWidget_date {
  display: flex;
  align-items: center;
}