.header {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
}

.headerFirst {
  margin-top: 0;
}

.header>h3 {
  align-self: center;
  margin-bottom: 5px;
}

.back {
  color: var(--tangerine-500);
  margin-right: 15px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-left: 35px;
}

.back:hover {
  color: var(--tangerine-400);
}

.viewHistory {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  margin-top: 5px;
  color: var(--grey-400);
  margin-bottom: 20px;
}

.viewHistory:hover {
  color: var(--grey-500);
}

.loadMore {
  background-color: var(--tangerine-500);
  color: white;
  margin-bottom: 10px;
  font-weight: 600;
}

.backArrow {
  padding-right: 3px;
}