.badge {
  width: 75px;
  cursor: pointer;
  margin: 10px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.badge>h3 {
  font-size: 18px;
  text-align: center;
}

.badge>p {
  color: var(--grey-400);
  margin-bottom: 5px;
}

.badge>img {
  width: 100%;
}