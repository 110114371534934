.iconCycling {
  background: url("../../../public/images/activity_icons/cycling.svg") no-repeat;
}

.iconSwimming {
  background: url("../../../public/images/activity_icons/swimming.svg") no-repeat;
}

.yoga {
  background: url("../../../public/images/activity_icons/yoga.svg") no-repeat;
}

.reading {
  background: url("../../../public/images/activity_icons/reading.svg") no-repeat;
}

.levelDifficult {
  background: url("../../../public/images/activity_icons/activity/activitiesicon_difficult.svg") no-repeat;
}

.levelMedium {
  background: url("../../../public/images/activity_icons/activity/activitiesicon_moderate.svg") no-repeat;
}

.levelEasy {
  background: url("../../../public/images/activity_icons/activity/activitiesicon_easy.svg") no-repeat;
}

.durationLong {
  background: url("../../../public/images/activity_icons/activity/activitiesicon_long.svg") no-repeat;
}

.durationMedium {
  background: url("../../../public/images/activity_icons/activity/activitiesicon_medium.svg") no-repeat;
}

.durationShort {
  background: url("../../../public/images/activity_icons/activity/activitiesicon_short.svg") no-repeat;
}

.icon {
  width: 40px;
  height: 40px;
}

.indicationIcon {
  width: 15px;
  height: 15px;
}

.iconActive {
  background-color: #f6c0ab !important;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.container {
  width: 100%;
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.activityCard {
  border-radius: 15px !important;
}

.indicationAvatar {
  width: 24px !important;
  height: 24px !important;
  background-color: #fff !important;
}

.subtitle {
  color: grey;
  font-size: 0.8rem !important;
}

.activityCompletionBtn {
  width: 80%;
  border-radius: 50px !important;
  text-transform: none !important;
  font-weight: 700 !important;
}

.activityDeleteBtn {
  width: 20%;
  border-radius: 50px !important;
}

.containerWidth {
  width: 80%;
}

.activityCardContent {
  padding-bottom: 4px;
  cursor: pointer;
}

.loadMoreBtn {
  width: 100%;
  margin: 0px;
  margin-top: 1em;
}

.activityHistoryCard {
  border-radius: 15px !important;
  padding: 1em;
  width: 80%;
}

.historyCardContent {
  padding-bottom: 4px !important;
  cursor: pointer;
  padding: 0px !important;
  color: #000000;
}

.cardText {
  margin-bottom: 0.5em;
}

.cardTextGrey {
  color: #494949;
}

.flexDisplay {
  display: flex;
}

.historyBackBtn {
  width: 100%;
  margin: 0px;
  margin-top: 1em;
  cursor: pointer;
}

.historyContainer {
  margin-top: 1em !important;
}

.activityDivider {
  width: 100%;
  margin-bottom: 1em !important;
  margin-top: 1.5em !important;
}

.addActivityBtnBox {
  display: flex;
  justify-content: center;
}

.addActivityBtn {
  margin-top: 1em;
}

.fullWidth {
  width: 100%;
}

.selectedPin {
  color: #f27558;
}

.pin {
  color: #e9e8e4;
}

.activityCheckin {
  flex-direction: column;
}