.emotion_modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.emotion_modalForm {
  background-color: var(--cream);
  width: 375px;
  height: fit-content;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  padding: 24px 16px;
}

.emotion_modalForm>button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  font-weight: 600;
}