.container {
  width: 80%;
  height: 50px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  margin: 6px 0;
}

.bigContainer {
  width: 80%;
  height: 70px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  margin: 6px 0;
}

.info {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 5px 15px;
}

.container>button {
  height: 20px;
  width: 20px;
  border: 2px solid var(--grey-400);
  background-color: white;
  border-radius: 15px;
  margin: 0 15px;
  cursor: pointer;
}

.container>button:hover {
  background-color: var(--grey-300);
}

.info>h3 {
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
}

.info>p {
  color: var(--grey-400);
  font-size: 14px;
  cursor: pointer;
}

.headerInactive {
  color: var(--grey-400);
}