.bubble {
  padding: 10px;
  max-width: 65%;
  box-shadow: none;
}

.leftBubble {
  margin-left: 10px;
  border-radius: 0px 20px 20px 20px !important;
}

.rightBubble {
  margin-right: 10px;
  border-radius: 20px 20px 0px 20px !important;
  color: white !important;
  background-color: #ee9b68 !important;
}

.leftBubbleContainer {
  padding-left: 20px;
}

.rightBubbleContainer {
  padding-right: 20px;
}

.bubbleDate {
  font-size: 0.8rem;
}

.bubbleDateLeft {
  text-align: left;
  margin-left: 22px !important;
  color: #8f8f8f !important;
}

.bubbleDateRight {
  text-align: right;
  margin-right: 22px !important;
  color: #8f8f8f !important;
}
