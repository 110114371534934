.bodyForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(var(--height) - 110px);
  align-items: center;
}

.bodyMobile {
  min-height: calc(100vh - 200px);
}