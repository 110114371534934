.header {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.btn {
  font-weight: 600;
  background-color: var(--cream);
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.btn_blue {
  color: var(--blue-500);
}

.btn_orange {
  color: var(--tangerine-500);
}

.body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 450px;
}

.bodyMobile {
  min-height: calc(100vh - 300px);
}

.header>span {
  font-weight: 700;
}

.field {
  margin-top: 20px;
}

.repeat {
  display: flex;
  align-items: center;
}

.repeat>p {
  padding-right: 5px;
  font-size: 12px;
}

.textField {
  width: 300px;
  margin: 5px !important;
  align-self: center;
}

.switchField {
  width: 300px;
  margin: 5px;
  align-self: center;
}

.datetextarea {
  width: 300px;
  margin: 5px 5px 15px !important;
  align-self: center;
}

.btns {
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
  margin-bottom: 20px;
  justify-content: space-between;
}

.delete {
  margin-top: 10px;
  width: 100%;
}